import { API_PATH } from '../const/api_paths'
import { removeAuthToken } from './_auth';

export const doGet = function (endpoint, data, callback = {}) {
  var headers = {
    method:   "GET",
    mode:     "cors",
    cache:    "no-cache",
    headers:  {"Content-Type": "application/json"},
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, headers, callback)
}

export const doPost = (endpoint, data, callback = {}) => {
  var options = {
    method:   "POST",
    mode:     "cors",
    cache:    "no-cache",
    headers:  {"Content-Type": "application/json"},
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, options, callback)
}

export const doPostWithHeaders = (endpoint, data, callback = {}) => {
  var options = {
    method:   "POST",
    headers:  {'Accept': 'application/json','Content-Type': 'application/json'},
    body:     JSON.stringify(data)
  }
  doFetchWithHeaders(endpoint, options, callback)
}

export const doAuthenticatedGet = (endpoint, data, callback = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  var token = windowGlobal.localStorage.getItem('billebotAuthToken')
  var headers = new Headers()
  headers.append("Accept", "application/json")
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "GET",
    mode:     "cors",
    cache:    "no-cache",
    headers:  headers,
  }
  doFetch(addQueryString(endpoint, data), options, callback)
}

export const doAuthenticatedPost = (endpoint, data, callback = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  var token = windowGlobal.localStorage.getItem('billebotAuthToken')
  var headers = new Headers()
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "POST",
    mode:     "cors",
    cache:    "no-cache",
    headers:  headers,
    body:     JSON.stringify(data),
  }
  doFetch(endpoint, options, callback)
}

export const doAuthenticatedPostFormData = (endpoint, form_data, callback = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  var token = windowGlobal.localStorage.getItem('billebotAuthToken')
  var headers = new Headers()
  // headers.append("Accept", "application/json")
  // headers.append("Content-Type", "application/x-www-form-urlencoded")
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "POST",
    // mode:     "cors",
    // cache:    "no-cache",
    headers:  headers,
    body:     form_data
  }
  doFetch(endpoint, options, callback)
}

export const doAuthenticatedPutFormData = (endpoint, form_data, callback = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  var token = windowGlobal.localStorage.getItem('billebotAuthToken')
  var headers = new Headers()
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "PUT",
    mode:     "cors",
    cache:    "no-cache",
    headers:  headers,
    body:     form_data
  }
  doFetch(endpoint, options, callback)
}

export const doAuthenticatedPut = (endpoint, data, callback = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  var token = windowGlobal.localStorage.getItem('billebotAuthToken')
  var headers = new Headers()
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "PUT",
    mode:     "cors",
    cache:    "no-cache",
    headers:  headers,
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, options, callback)
}

export const doAuthenticatedDelete = (endpoint, data, callback = {}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  var token = windowGlobal.localStorage.getItem('billebotAuthToken')
  var headers = new Headers()
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", `Bearer ${token}`)

  var options = {
    method:   "DELETE",
    mode:     "cors",
    cache:    "no-cache",
    headers:  headers,
    body:     JSON.stringify(data)
  }
  doFetch(endpoint, options, callback)
}

export const doFetch = (endpoint, headers, callback) => {
  fetch(addTimestamp(API_PATH(endpoint)), headers)
  .then(res => res.json())
  .then(res=> {
    if(res.status === "ok" || res.status === "success") {
      if(callback.success) { callback.success(res) }
    } else if(res.error == "You need to sign in or sign up before continuing.") {
      removeAuthToken()
      window.location = '/'
    } else if(res.status === "error" || res.error) {
      if(callback.error) { callback.error(res) }
    } else {
      if(callback.general) { callback.general(res) }
    }
  })
  .catch(res => {
    if(callback.catch) { callback.catch(res) }
  })
}

export const doFetchWithHeaders = (endpoint, headers, callback) => {
  var local_headers = {};
  fetch(API_PATH(endpoint), headers)
  .then(res => {
    local_headers = res.headers;
    return res.json()
  })
  .then(res=> {
    if(res.status === "ok" || res.status === "success") {
      if(callback.success) {
        callback.success({...res, headers: local_headers})
      }
    } else if(res.error == "You need to sign in or sign up before continuing.") {
      removeAuthToken()
      window.location = '/'
    } else if(res.status === "error" || res.error) {
      if(callback.error) { callback.error(res) }
    }
  })
  .catch(res => {
    if(callback.catch) { callback.catch(res) }
  })
}


export const addTimestamp = (endpoint) => {
  const ts = `t=${(new Date).getTime()}`;
  const sep = endpoint.includes("?") ? '&' : '?';
  return endpoint + sep + ts;
}

export const addQueryString = (endpoint, params) => {
  try {
    const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    const sep = endpoint.includes("?") ? '&' : '?';
    return (qs == '') ? endpoint : endpoint + sep + qs;
  } catch(err) {
    return endpoint
  }

}

export const doAuthenticatedGetPromise = (endpoint) => {
  return new Promise((resolve, reject) => {
    const callback = {
      success: (response) => resolve(response),
      error: (error) => reject(error),
      general: (response) => resolve(response),
    };

    doAuthenticatedGet(endpoint, {}, callback);
  });
};