const KEY = 'billebotAuthToken'
const localStorage = typeof window !== 'undefined' && window.localStorage

export const setAuthToken = (authToken) => localStorage && localStorage.setItem(KEY, authToken)

export const removeAuthToken = () => localStorage && localStorage.removeItem(KEY)

export const getAuthToken = () => localStorage && localStorage.getItem(KEY)

export const hasAuthToken = () => Boolean(getAuthToken())

export const getBearerToken = () => {
  const authToken = getAuthToken()
  if (authToken) {
    return `Bearer ${authToken}`
  }
}

export const addAuthorizationHeaderToRequest = (request, next) => {
  const bearerToken = getBearerToken()
  if (bearerToken) {
    request.options.headers = request.options.headers || {}
    request.options.headers.authorization = bearerToken
  }
  if (next) {
    next()
  }
}

export const signOut = () => {
  stopImpersonation()
  removeAuthToken()
  window.location = '/'
}

export const stopImpersonation = () => {
  localStorage && localStorage.removeItem('billebotOriginalToken');
  localStorage && localStorage.removeItem('isImpersonating');
  localStorage && localStorage.removeItem('impostor');
  localStorage && localStorage.removeItem('impersonatePromptCount');
  localStorage && localStorage.removeItem('stopImpersonatePromptCount');
};
